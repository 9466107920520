import { Category, HeroImage, Intro, Pagination, Section } from "@components/Work"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Arrow } from "@components/UI"
import { Button } from "@components/Button"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import React from "react"
import { Seo } from "@components/Seo"
import { graphql } from "gatsby"
import { splitText } from "@utils"
import { usePageSetup } from "@components/usePageSetup"
import workData from "@json/work.json"

const TheChildrensPlacePage = ({ data }) => {
  const {
    galleryOneImage,
    galleryTwoImage,
    galleryThreeImage,
    galleryFourImage,
    headSpaceDesktopImage,
    headSpaceMobileImage,
    materialsImage,
    tileOneImage,
    tileTwoImage,
    journeyOneImage,
    journeyTwoImage,
    journeyThreeImage,
  } = data
  const { name, headline, theme, url } = workData["childrens"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-gray-darkest" fillColor="gray-darkest" />

      <main className="overflow-hidden">
        <HeroImage client="childrens" />
        <Category>ESG, Social Good Storytelling</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>
              Capturing the spirit of social good for one of America’s largest children’s clothing retailers.
            </Intro.Title>
            <div className="mt-10">
              <p>
                The Children’s Place (TCP) has grown from a North American brick-and-mortar to a global, digital-first
                specialty retailer—and so have their environmental, social, and governance (ESG) practices and programs.
              </p>
              <p>
                As the next step in a decade-long sustainability journey, they needed to translate their efforts into
                something meaningful for TCP associates and a growing younger customer base.
              </p>
              <p>
                So we crafted a sustainability story that expresses each of their ESG pillars through a brand new
                messaging platform and refreshed visual identity, all of which debuted in their{" "}
                <a
                  href="https://corporate.childrensplace.com/static/TCP_SUS_IMPACT_2023-5d3f50b1f96c8ef071586695a889e39d.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  2023 impact report.
                </a>
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Design</li>
            <li>Visual storytelling</li>
            <li>Brand alignment</li>
            <li>Strategy &amp; execution</li>
            <li>Project management</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <div className="mt-clamp-17-22">
            <div className="container relative flex">
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <div className="w-full sm:w-5/6 lg:w-9/12">
                <Carousel>
                  <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                    <Carousel.Slide>
                      <div data-aos="fade-up" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(galleryOneImage)} alt="Gallery 1" />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="200" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(galleryTwoImage)} alt="Gallery 2" />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(galleryThreeImage)} alt="Gallery 3" />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="600" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(galleryFourImage)} alt="Gallery 4" />
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-clamp-25-44 pt-clamp-24-37 bg-work-childrens-orange">
          <Section className="col-span-9 text-white xl:col-span-10">
            <Section.Title className="text-white">Getting into the right head—and heart—space.</Section.Title>
            <Section.Description>
              <p>
                Interviews with key employees in different roles and regions revealed that across the board, TCP
                associates wanted a clearer, more accessible way to talk about the company’s sustainability
                initiatives—both internally and externally.
              </p>
              <p>
                A collaborative messaging workshop with the ESG team helped us get to the heart of their story: the
                attitudes they want to encourage in others, how they want to make people feel, and what makes their
                social good efforts truly great.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative flex justify-center mt-clamp-15-22">
            <div className="absolute inset-0 flex">
              <div className="w-full mt-auto aspect-w-326 aspect-h-170 sm:aspect-w-1436 sm:aspect-h-270 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div data-aos="fade-up" className="w-full md:w-10/12">
              <div className="hidden sm:block">
                <GatsbyImage
                  image={getImage(headSpaceDesktopImage)}
                  alt="Getting into the right head—and heart—space."
                />
              </div>
              <div className="sm:hidden">
                <GatsbyImage
                  image={getImage(headSpaceMobileImage)}
                  alt="Getting into the right head—and heart—space."
                />
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-38-71">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Crafting their story, stitch by stitch.</Section.Title>
            <Section.Description>
              <p>
                “Protecting the future of our PLACE” seamlessly ties into TCP’s corporate brand while uniquely wrapping
                their ESG work in the personality they exude: purposeful, joyful, and endlessly optimistic.
              </p>
              <p>
                We developed key messaging that translates each pillar into inspiring yet functional language that helps
                tell a cohesive, authentic, and approachable story about the good they’re doing in the world.
              </p>
              <p>
                And a messaging playbook provides clear, easy-to-use guidelines that enable all associates to
                communicate effectively about sustainability, both inside and out.
              </p>
            </Section.Description>
          </Section>
          <div className="container pb-clamp-18-35 pt-clamp-10-30">
            <div>
              <GatsbyImage image={getImage(materialsImage)} alt="Crafting their story, stitch by stitch." />
            </div>
          </div>

          <Section className="col-span-9 xl:col-span-8 pb-clamp-16-26">
            <Section.Title>An impact(ful) report.</Section.Title>
            <Section.Description>
              <p>
                With the messaging done, it was time to bring it all to life in their 2023 Sustainability & Social
                Impact Update. Building off the award-winning visual identity we had created earlier in our partnership,
                we created a refreshed look and feel that incorporates thoughtful design elements, clear data
                visualizations, and playful imagery.
              </p>
              <p>
                We paired rounded triangles—reminiscent of clothing hangers and symbolizing forward movement—with
                vibrant colors and playful imagery to capture the spirit of the brand—and the children who wear their
                clothing.
              </p>
              <p>
                Then, we leveraged their new story in headlines and supporting copy across the report, weaving together
                facts and feelings to create a deeper connection with ESG at TCP.
              </p>
              <div className="mt-7">
                <Button
                  external={true}
                  link="https://corporate.childrensplace.com/static/TCP_SUS_IMPACT_2023-5d3f50b1f96c8ef071586695a889e39d.pdf"
                >
                  View impact report
                </Button>
              </div>
            </Section.Description>
          </Section>
        </section>

        <section className="bg-work-childrens-green pt-clamp-17-32">
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="w-full aspect-w-1436 aspect-h-194 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="flex flex-col grid-cols-6 grid-rows-6 gap-10 sm:gap-0 sm:grid lg:grid-rows-4 lg:grid-cols-12">
              <div className="col-start-1 col-end-6 row-start-1 row-end-5 lg:row-end-4 lg:col-end-11">
                <GatsbyImage image={getImage(tileOneImage)} alt="Tile 1" />
              </div>
              <div className="flex col-start-2 col-end-7 row-start-3 row-end-7 lg:row-start-2 lg:row-end-5 lg:col-start-5 lg:col-end-13">
                <div className="mt-auto">
                  <GatsbyImage image={getImage(tileTwoImage)} alt="Tile 2" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="bg-work-childrens-blue pt-clamp-14-30 mt-clamp-28-50">
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="w-full aspect-w-1436 aspect-h-385 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="flex flex-col grid-cols-2 gap-y-8 lg:gap-y-4 lg:gap-x-4 lg:grid">
              <div className="flex justify-center order-1">
                <div className="w-5/6">
                  <h2 className="font-bold text-clamp-25-40">The journey continues.</h2>
                  <p className="mt-6">
                    As is the case for many organizations, TCP’s sustainability journey will never be truly complete.
                    But as they continue to create a brighter world for future generations, they’ll have the tools they
                    need to talk about their programs, practices, and progress.
                  </p>
                  <p>
                    Our partnership also continues to evolve with consumer research focused on how the next generation
                    of customers think and feel about sustainability.
                  </p>
                </div>
              </div>
              <div className="order-2 mt-2 lg:mt-0">
                <GatsbyImage image={getImage(journeyOneImage)} alt="Journey 1" />
              </div>
              <div className="order-4 lg:order-3">
                <div className="lg:mt-16">
                  <GatsbyImage image={getImage(journeyTwoImage)} alt="Journey 2" />
                </div>
              </div>
              <div className="order-3 rounded-sm lg:order-4">
                <GatsbyImage image={getImage(journeyThreeImage)} alt="Journey 3" />
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-50-68 pb-clamp-27-43">
          <div className="container flex justify-center">
            <div data-aos="fade-up" className="w-full xl:w-10/12">
              <Carousel.Quote client="children" single={true}></Carousel.Quote>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
        <Pagination client="ad" />
      </main>
    </Layout>
  )
}

export default TheChildrensPlacePage

export const theChildrensPlaceQuery = graphql`
  query theChildrensPlacePageQuery {
    galleryOneImage: file(relativePath: { eq: "work/childrens/gallery-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryTwoImage: file(relativePath: { eq: "work/childrens/gallery-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryThreeImage: file(relativePath: { eq: "work/childrens/gallery-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryFourImage: file(relativePath: { eq: "work/childrens/gallery-4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    headSpaceDesktopImage: file(relativePath: { eq: "work/childrens/head-space.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    headSpaceMobileImage: file(relativePath: { eq: "work/childrens/head-space.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    materialsImage: file(relativePath: { eq: "work/childrens/materials.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    tileOneImage: file(relativePath: { eq: "work/childrens/tile-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    tileTwoImage: file(relativePath: { eq: "work/childrens/tile-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    journeyOneImage: file(relativePath: { eq: "work/childrens/journey-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    journeyTwoImage: file(relativePath: { eq: "work/childrens/journey-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    journeyThreeImage: file(relativePath: { eq: "work/childrens/journey-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
